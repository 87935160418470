import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Privacy from "./Privacy";
import Recipe from "./Recipe";

const LandingRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/privacy-policy" element={<Privacy />} />
      <Route exact path="/recipe" element={<Recipe />} />
    </Routes>
  );
};

export default LandingRoutes;
