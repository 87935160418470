import { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { Navigation } from "./components/navigation"

const Recipe = () => {
    const containerStyle = {
        all: 'initial',
        display: 'block',
        boxSizing: 'border-box',
        color: 'black',
        fontSize: '16px',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        backgroundColor: 'white',
    };

    const [searchParams] = useSearchParams();
    const [recipe, setRecipe] = useState(null);

    const uuid = searchParams.get("uuid");

    useEffect(() => {
        const listStyle = {
            listStyleType: 'circle',
            marginBottom: '15px',
            paddingLeft: '20px',
        };

        const listItemStyle = {
            marginBottom: '5px',
        };

        const url = process.env.REACT_APP_BASE_URL + "recipe?uuid=" + uuid;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                setRecipe(
                    <div>
                        <h1>{data.title}</h1>
                        <p><i>{data.description}</i></p>
                        <p>Ingredients:</p>
                        <ul style={listStyle}>
                            {data.ingredients.map((ingredient, index) => {
                                return <li style={listItemStyle} key={index}>{ingredient}</li>
                            })}
                        </ul>
                        <p>Instructions:</p>
                        <ol style={listStyle}>
                            {data.instructions.map((instruction, index) => {
                                return <li style={listItemStyle} key={index}>{instruction}</li>
                            })}
                        </ol>
                    </div>
                );
            })
    }, [uuid]);

    return (
        <div style={containerStyle}>
            <Navigation />
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                Welcome to Recipe Remix!&nbsp;
                <a href={process.env.REACT_APP_DOWNLOAD_URL}>
                    Download the iPhone app
                </a>
                &nbsp;to get started!
            </p>
            {recipe}
        </div>
    )
}

export default Recipe;